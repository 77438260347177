.App {
  color: #333;
  margin: auto;
  width: 100%;
}

.App a {
  color: #878583;

}


.App-header {
  color: white;
  margin-top: 80px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  width: 90%;
}

.Header-links a {
  text-decoration: none;
  font-size: large;
  font-weight: 900;
  font-family: 'Lato', sans-serif;
  text-transform: uppercase;
}
.App-link {
  color: #61dafb;
}

.Header-links {
  display: flex;
}

.Header-link {
  margin-right: 10px;
}
.Header-dash {
  margin-right: 10px;
  color: #878583;
}

.Bg {
  background-image: url(./images/bg.jpg);
}

.App-body {
  width: 900px;
  margin: auto;
}

.About {
  /* background-color: white; */
  display: flex;
  font-family: 'Lato', sans-serif;
}

.AboutImage {
  color: white;
  margin-bottom:25px;
}

.AboutSide {
  margin-bottom: 500px;
  margin-left: 15px;
}

.AboutBio {
  color: #333;
  margin-bottom: 80px;
 
}

.AboutLinks{

}

.AboutLinks a {

}

h3 {
  margin-bottom: 10px;
  font-size: large;
}


.Clip {
  
  margin-bottom: 100px;
  color: #555453;
  font-family: 'Lato', sans-serif;
}

.Clip-Title {
  font-weight: 600;
  /* font-size: large; */
  margin-top: 15px;
  margin-bottom: 5px;
  color: #181717;
}

.Clip-ClipName {
  color:rgb(163, 108, 117);
}

.Clip-Website {
  margin-top: 5px;
  margin-bottom: 55px;
  color: #555453;
}

.Clip-Website a {  
  color: #555453;
}

.vidFrame {
  /* width:1100px;
  height:621px; */
  width:900px;
  height:508px;
}

.Videos {
  margin-bottom: 400px;
  
}





@media (max-width: 900px) {
  .vidFrame{
    width:100%;
  }
  .App-header {
    justify-content: center;
  }
  .App-body {
    width: 100%;
  }
  
}
@media (max-width: 850px) {
  .vidFrame{
    width:100%;
  }
  .App-header {
    justify-content: center;
  }
  .App-body {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .vidFrame{
    width:100%;
  }
  .App-header {
    justify-content: center;
  }
  .App-body {
    width:100%;
  }
}
@media (max-width: 750px) {
  .vidFrame{
    width:100%;
  }
  .App-header {
    justify-content: center;
  }
  .App-body {
    width: 100%;
  }
}












@media (max-width: 500px) {

  .vidCap {
    width: 426px;
  }

  .App {
    margin: 0;
  }
  .App-body {
    margin: 0;
    width: 100%;
  }

  .top-links {
    flex-direction: column;
  }

   .vidFrame{
    width:426px;
    height:240px;
  }
  .About {
    flex-direction: column;

  }

  .App-header {
    justify-content: center;
  }
  .AboutBio {
    padding-top:10px;
    margin-right: 10px;
  }

  .Clip-Info {
    margin-right: 15px;
    margin-left: 15px;
  }

  .AboutImage img{
    width: 250px;
    margin-bottom:25px;
    margin: 0;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Girl {
  position: fixed; 
  bottom: 0px; 
  right: 0px;
}

.Girl img {
  width: 120px;
}